import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { OvertimeUndertimeResponseSuccess } from 'api';
import { useFetchPayments } from 'hooks';

import { OvertimeUndertimeRequestsQueryParameters } from '../types';

function useOvertimeUndertimeRequestQuery<SelectedData = OvertimeUndertimeResponseSuccess>(
  { year, month, typeOfAssociation }: OvertimeUndertimeRequestsQueryParameters,
  options?: UseQueryOptions<OvertimeUndertimeResponseSuccess, Error, SelectedData>,
): UseQueryResult<SelectedData, Error> {
  const authorisedFetch = useFetchPayments();

  return useQuery<OvertimeUndertimeResponseSuccess, Error, SelectedData>(
    ['overtime_undertime_requests', month, year, typeOfAssociation],
    async () => {
      const response = await authorisedFetch(
        `ext/over-under-times?year=${year}&month=${month}&type_of_association=${typeOfAssociation}`,
        {
          method: 'GET',
        },
      );

      return await response.json();
    },
    options,
  );
}

export default useOvertimeUndertimeRequestQuery;
