import { useCallback } from 'react';

import { Resources } from 'api';
import useOvertimeUndertimeRequestQuery from 'features/overtimeUndertime/hooks/useOverimeUndertimeRequestQuery';
import { OvertimeUndertimeRequestType } from 'features/overtimeUndertime/types';
import { useAuth, useProfileQuery, useRequestsToApproveQuery } from 'hooks';
import { getPreviousRequestDate } from 'utils/getPreviousRequestDate';

interface LeftSidebarContentHandlers {
  unresolvedOvertimeRequestsCount: number;
  unresolvedRequestsCount: number;
  resources: Resources;
}

function useLeftSidebarContentHandlers(): LeftSidebarContentHandlers {
  const { resources, id } = useAuth();
  const { data: { firstName, lastName } = {} } = useProfileQuery(id, {
    enabled: !!id,
  });

  const { year, month } = getPreviousRequestDate();

  const { data: unresolvedRequestsCount = 0 } = useRequestsToApproveQuery<number>(id, {
    enabled: Boolean(resources?.pending_requests),
    select: (data) =>
      data.filter(
        ({ status, approvals }) =>
          (status === 'in_progress' || status === 'new') &&
          approvals.some(
            ({ approver, status }) => approver.cdID === id && status === 'in_progress',
          ),
      ).length,
  });

  const getReassignCount = useCallback(
    (data: OvertimeUndertimeRequestType[]) =>
      data.filter(
        ({ status, approver }) =>
          status === 'reassigned' && approver === `${firstName} ${lastName}`,
      ).length,
    [firstName, lastName],
  );

  const { data: unresolvedAssociateOvertimeRequestsCount = 0 } =
    useOvertimeUndertimeRequestQuery<number>(
      {
        year,
        month,
        typeOfAssociation: 'associate',
      },
      {
        enabled: !!resources?.compensations,
        select: ({ data }) => getReassignCount(data),
      },
    );

  const { data: unresolvedFreelancerOvertimeRequestsCount = 0 } =
    useOvertimeUndertimeRequestQuery<number>(
      {
        year,
        month,
        typeOfAssociation: 'freelancer',
      },
      {
        enabled: !!resources?.compensations,
        select: ({ data }) => getReassignCount(data),
      },
    );

  return {
    unresolvedOvertimeRequestsCount:
      unresolvedAssociateOvertimeRequestsCount + unresolvedFreelancerOvertimeRequestsCount,
    unresolvedRequestsCount,
    resources,
  };
}

export default useLeftSidebarContentHandlers;
