import { ReactElement } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Layout } from 'components/layout';
import { NotFoundPage } from 'features/notFoundPage';
import { useAuth } from 'hooks';
import { ProtectedRoute } from 'routes/components';
import { ROUTE_PATHS } from 'settings';
import { permissionsHandler } from 'utils';

import {
  TimeTrackingLogDetailsPage,
  AnswerEsatPage,
  ApprovalTreePage,
  CompensationsPage,
  CompleteAnswerEsatPage,
  ContractorsPage,
  CreateEsatFormPage,
  CreateQuestionnairePage,
  EditProfilePage,
  ImportDismissalReasonsPage,
  LoginPage,
  NineBoxAssessPage,
  NineBoxAssessmentsStatusPage,
  NineBoxResultPage,
  OvertimeUndertimePage,
  PendingRequestsPage,
  ProfilePage,
  RiskRadarPage,
  RolesAndPermissionsPage,
  SurveysPage,
  TimeOffBalancePage,
  TimeOffReportPage,
  TimeTrackingPage,
  PaymentRequiredPage,
  FaqPage,
  AppInfoPage,
} from './lazyComponents';
import { availableTimeTrackingRoute } from './utils';

const {
  ROOT,
  AUTHORIZATION,
  ASSOCIATES,
  TIME_OFF_BALANCE,
  PENDING_REQUESTS,
  TIME_OFF_REPORT,
  COMPENSATIONS,
  ROLES_AND_PERMISSIONS,
  SURVEYS,
  RISK_RADAR,
  IMPORT_DISMISSAL_REASONS,
  OVERTIME_UNDERTIME,
  TIME_TRACKING,
  FAQ,
  APP_INFO,
  PAYMENT_REQUIRED,
} = ROUTE_PATHS;

function AppRoutes(): ReactElement {
  const { isAuth, resources } = useAuth();

  const {
    canLogTime,
    canApproveTimeSheet,
    canManageLogPeriods,
    canManageIssue,
    canViewAssociateSection,
    canViewTimeOffBalance,
  } = permissionsHandler(resources);

  return (
    <Routes>
      <Route element={<ProtectedRoute isAllowed={!isAuth} redirectPath={ROOT} />}>
        <Route path={AUTHORIZATION.SIGNIN.INDEX} element={<LoginPage />} />
      </Route>
      <Route path={ROOT} element={<Layout />}>
        <Route
          element={<ProtectedRoute isAllowed={isAuth} redirectPath={AUTHORIZATION.SIGNIN.INDEX} />}
        >
          <Route index element={<Navigate to={FAQ.INDEX} replace />} />
          <Route path={FAQ.INDEX} element={<FaqPage />} />
          <Route element={<ProtectedRoute isAllowed={canViewTimeOffBalance} redirectPath={ROOT} />}>
            <Route path={TIME_OFF_BALANCE.INDEX} element={<TimeOffBalancePage />} />
          </Route>

          <Route
            element={<ProtectedRoute isAllowed={canViewAssociateSection} redirectPath={ROOT} />}
          >
            <Route path={ASSOCIATES.INDEX} element={<ContractorsPage />} />
            <Route path={ASSOCIATES.APPROVAL_TREE_BY_ID.INDEX} element={<ApprovalTreePage />} />
          </Route>

          <Route path={ASSOCIATES.BY_ID} element={<ProfilePage />} />
          <Route
            element={
              <ProtectedRoute
                isAllowed={Boolean(resources?.associates?.edit_contractor_yame)}
                redirectPath={ROOT}
              />
            }
          >
            <Route path={ASSOCIATES.EDIT_PROFILE_BY_ID.INDEX} element={<EditProfilePage />} />
          </Route>

          {process.env.REACT_APP_ENVIRONMENT &&
          ['local', 'test'].includes(process.env.REACT_APP_ENVIRONMENT) ? (
            <Route path={COMPENSATIONS.INDEX} element={<CompensationsPage />} />
          ) : null}

          <Route
            element={
              <ProtectedRoute isAllowed={Boolean(resources?.compensations)} redirectPath={ROOT} />
            }
          >
            <Route path={OVERTIME_UNDERTIME.TABS} element={<OvertimeUndertimePage />} />

            <Route
              path={OVERTIME_UNDERTIME.INDEX}
              element={<Navigate to={OVERTIME_UNDERTIME.ASSOCIATES.INDEX} />}
            />
          </Route>

          <Route
            element={
              <ProtectedRoute
                isAllowed={Boolean(resources?.pending_requests)}
                redirectPath={ROOT}
              />
            }
          >
            <Route path={PENDING_REQUESTS.INDEX} element={<PendingRequestsPage />} />
          </Route>
          <Route
            element={
              <ProtectedRoute isAllowed={Boolean(resources?.time_off_report)} redirectPath={ROOT} />
            }
          >
            <Route path={TIME_OFF_REPORT.INDEX} element={<TimeOffReportPage />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAllowed={Boolean(resources?.rolesAndPermissions)}
                redirectPath={ROOT}
              />
            }
          >
            <Route path={ROLES_AND_PERMISSIONS.INDEX} element={<RolesAndPermissionsPage />} />
          </Route>
          <Route path={SURVEYS.BY_ID} element={<AnswerEsatPage />} />
          <Route path={SURVEYS.COMPLETE} element={<CompleteAnswerEsatPage />} />
          <Route
            element={
              <ProtectedRoute
                isAllowed={Boolean(
                  resources?.surveys?.view_esat_results ||
                    resources?.surveys?.view_list_of_all_9box_questionnaires,
                )}
                redirectPath={ROOT}
              />
            }
          >
            <Route path={SURVEYS.INDEX} element={<SurveysPage />} />
            <Route
              element={
                <ProtectedRoute
                  isAllowed={Boolean(resources?.surveys?.create_esat_questionnaire)}
                  redirectPath={ROOT}
                />
              }
            >
              <Route
                path={SURVEYS.CREATE_QUESTIONNAIRE.INDEX}
                element={<CreateQuestionnairePage />}
              />
              <Route
                path={SURVEYS.CREATE_QUESTIONNAIRE.ESAT.INDEX}
                element={<CreateEsatFormPage />}
              />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  isAllowed={Boolean(resources?.surveys?.assess_9box_questionnaire)}
                  redirectPath={SURVEYS.INDEX}
                />
              }
            >
              <Route path={SURVEYS.NINE_BOX.ASSESS} element={<NineBoxAssessPage />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  isAllowed={Boolean(resources?.surveys?.edit_results_9box_questionnaire)}
                  redirectPath={SURVEYS.INDEX}
                />
              }
            >
              <Route path={SURVEYS.NINE_BOX.EDIT} element={<NineBoxAssessPage />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  isAllowed={Boolean(resources?.surveys?.view_results_9box_questionnaire)}
                  redirectPath={SURVEYS.INDEX}
                />
              }
            >
              <Route path={SURVEYS.NINE_BOX.RESULT} element={<NineBoxResultPage />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  isAllowed={Boolean(resources?.surveys?.status_9box_questionnaire)}
                  redirectPath={SURVEYS.INDEX}
                />
              }
            >
              <Route
                path={SURVEYS.NINE_BOX.ASSESSMENT_STATUS}
                element={<NineBoxAssessmentsStatusPage />}
              />
            </Route>
          </Route>
          <Route path={IMPORT_DISMISSAL_REASONS.INDEX} element={<ImportDismissalReasonsPage />} />
          <Route
            element={
              <ProtectedRoute
                isAllowed={Boolean(resources?.risk_radar?.view_radar)}
                redirectPath={ROOT}
              />
            }
          >
            <Route path={RISK_RADAR.INDEX} element={<RiskRadarPage />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAllowed={
                  canLogTime || canApproveTimeSheet || canManageLogPeriods || canManageIssue
                }
                redirectPath={ROOT}
              />
            }
          >
            <Route path={TIME_TRACKING.TABS} element={<TimeTrackingPage />} />
            <Route
              path={TIME_TRACKING.INDEX}
              element={<Navigate to={availableTimeTrackingRoute(resources)} />}
            />
          </Route>
        </Route>
        <Route
          element={
            <ProtectedRoute isAllowed={canApproveTimeSheet} redirectPath={TIME_TRACKING.INDEX} />
          }
        >
          <Route path={TIME_TRACKING.LOGS.BY_ID} element={<TimeTrackingLogDetailsPage />} />
        </Route>
      </Route>
      <Route path={PAYMENT_REQUIRED.INDEX} element={<PaymentRequiredPage />} />
      <Route path={APP_INFO.INDEX} element={<AppInfoPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppRoutes;
