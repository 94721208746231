import { COMPANY } from 'api';

export const appPathStrategy = (name: COMPANY) => {
  switch (name) {
    case COMPANY.yalantis:
      return COMPANY.yalantis;

    default:
      return COMPANY.sherp;
  }
};
