import { ThemeAppearance } from '@atlaskit/lozenge';

import { OvertimeUndertimeStatus } from 'features/overtimeUndertime/types';

import {
  CompensationRequestStatus,
  RequestStatus,
  RequestType,
  ResolutionStatus,
  TimeOfBalanceTitleType,
} from './types';

export const localStorageAccessKey = '__auth_provider_access__';
export const localStorageResourcesAccessKey = '__auth_resources_access__';
export const rolesAndPermissionsTabsAccessKey = 'roles_and_permissions_tabs';

export const YA_ME_INTERNAL_SYSTEM_CODE = 'ya-me';

export const ASSOCIATES_YA_ME_PAGE_KEY = 'associates_ya_me_page_key';

export const requestStatusAppearance: { [key: string]: ThemeAppearance } = {
  [RequestStatus.approved]: 'success',
  [RequestStatus.declined]: 'removed',
  [RequestStatus.in_progress]: 'default',
  [RequestStatus.new]: 'default',
  [RequestStatus.canceled]: 'removed',
} as const;

export const compensationRequestStatusAppearance: { [key: string]: ThemeAppearance } = {
  [CompensationRequestStatus.approved]: 'success',
  [CompensationRequestStatus.declined]: 'removed',
  [CompensationRequestStatus.in_progress]: 'default',
  [CompensationRequestStatus.new]: 'inprogress',
  [CompensationRequestStatus.needs_update]: 'removed',
  ['pending']: 'inprogress',
  ['needs_corrections']: 'removed',
} as const;

export const resolutionStatusAppearance: { [key: string]: ThemeAppearance } = {
  [ResolutionStatus.approved]: 'success',
  [ResolutionStatus.declined]: 'removed',
  [ResolutionStatus.in_progress]: 'default',
  [ResolutionStatus.canceled]: 'removed',
} as const;

export const requestTypeAppearance: { [key: string]: ThemeAppearance } = {
  [RequestType.undocumentedSickLeave]: 'inprogress',
  [RequestType.documentedSickLeave]: 'new',
  [RequestType.paidVacation]: 'success',
  [RequestType.unpaidVacation]: 'moved',
  [RequestType.additionalVacation]: 'default',
} as const;

export const overtimeUndertimeStatusAppearance: { [key: string]: ThemeAppearance } = {
  [OvertimeUndertimeStatus.not_resolved]: 'default',
  [OvertimeUndertimeStatus.paid_standard]: 'inprogress',
  [OvertimeUndertimeStatus.paid_as_logged]: 'inprogress',
  [OvertimeUndertimeStatus.approved_change]: 'success',
  [OvertimeUndertimeStatus.reassigned]: 'new',
};

export const requestTypeLabel = {
  ['undocumented_sick_leave']: 'Sick leave',
  ['documented_sick_leave']: 'Documented sick leave',
  ['paid_vacation']: 'Paid vacation',
  ['unpaid_vacation']: 'Unpaid vacation',
  ['additional_vacation']: 'Additional day off',
} as const;

export const durationLabel = {
  HalfDay: '4h',
  Day: '8h',
} as const;

export const requestStatusLabelOptions = {
  in_progress: 'in progress',
  approved: 'approved',
  declined: 'declined',
  new: 'new',
} as const;

export const monthsShortForm = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const questionnaireStatusAppearance: { [key: string]: ThemeAppearance } = {
  SCHEDULED: 'inprogress',
  ONGOING: 'default',
  COMPLETED: 'success',
} as const;

export const WORKING_HOURS_OF_THE_DAY = 8;

export const archivedStatusLabelOptions = {
  Active: 'Active',
  Archived: 'Archived',
} as const;

export const sourceOfChangedTooltipText = {
  mc: 'Can be edited in Mission Control',
  erp: 'Can be edited in ERP',
  cep: 'Can be edited in CEP',
} as const;

export const timeOfBalanceTitle: TimeOfBalanceTitleType = {
  [RequestType.undocumentedSickLeave]: 'Sick leaves',
  [RequestType.paidVacation]: 'Paid vacation',
  [RequestType.documentedSickLeave]: 'Documented Sick leaves',
  [RequestType.unpaidVacation]: 'Unpaid vacation',
  [RequestType.additionalVacation]: 'Additional days off',
  paid_vacation_next_year: 'Paid vacation (next year)',
  paid_vacation_last_year: 'Paid vacation (last year)',
};

export const timeOffTypeAction = {
  Add: 'deposit',
  Remove: 'withdraw',
} as const;

interface PermissionsType {
  ERP: 'erp';
  YA_ME: 'ya-me';
  CEP: 'cep';
  PAYMENTS: 'payments';
  MC: 'mc';
}

export const APPS_PERMISSIONS: PermissionsType = {
  ERP: 'erp',
  YA_ME: 'ya-me',
  PAYMENTS: 'payments',
  CEP: 'cep',
  MC: 'mc',
};

export enum COMPANY {
  yalantis = 'yalantis',
  sherp = 'sherp',
  temy = 'temy',
  sab = 'sab',
  tallium = 'tallium',
  artkai = 'artkai',
}
