import { Options, ReassignContractorType } from 'api';

import { modalVariantsAssociateTab, modalVariantsFreelancerTab } from './constants';

export type ModalVariant =
  | ''
  | typeof modalVariantsAssociateTab[number]
  | typeof modalVariantsFreelancerTab[number];

export interface RequestStatusFormValues {
  id: string;
  status: OvertimeUndertimeRequestStatusType;
  approver_email?: string;
  comment?: OvertimeUndertimeRequestType['comment'];
  sum?: number;
  initialStatus?: OvertimeUndertimeRequestStatusType;
  user_id: string;
  planned_hours: OvertimeUndertimeRequestType['planned_hours'];
  apply_to: string;
  children: {
    project_id: string | number;
    planned_hours: number;
    actual_hours: number;
    recommended_sum: number;
  }[];
  recommended_sum?: number;
  type_of_association: OvertimeUndertimeRequestType['type_of_association'];
}

export interface OvertimeUndertimeFilters {
  statusOptions: Options<string, string>[];
  deliveryUnitsOptions: Options<string, string>[];
  jobProfileOptions: Options<string, string>[];
  projectOptions: Options<string, string>[];
  overtimeUndertimeOptions: Options<string, string>[];
}

export type FiltersData = {
  [key: string]: Options<string, string>[] | string | Options<string, string>;
};

export interface RequestsFiltersProps {
  filtersOptions: OvertimeUndertimeFilters | null;
  handleRequestFiltersFormSubmit: (data: FiltersData) => void;
  handleResetFilters: (resetForm: () => void) => void;
  currentDate: { year: number; month: number };
  handleSearchChange: (
    value: string,
    setFieldValue: (fieldName: string, value: string) => void,
    submitForm: () => void,
  ) => void;
  countOfSelectedFilters: number;
  isFiltersOpen: boolean;
  handleFiltersVisibility: { open: () => void; close: () => void };
  defaultFiltersData: DefaultFiltersData;
}

export type RequestErrorType =
  | Record<'no_rate' | 'not_found', Record<string, unknown>>
  | Record<string, unknown>;

export type DefaultFiltersData = {
  month: string;
  overtimeUndertime: Options<string, string>[];
};

export enum OvertimeUndertimeFilter {
  All = 'All',
  Overtime = 'Overtime',
  Undertime = 'Undertime',
}

export type OverUnderTimeTab = 'associate' | 'freelancer';

export interface OvertimeUndertimeRequestsQueryParameters {
  year: number;
  month: number;
  typeOfAssociation: OverUnderTimeTab;
}

export type ReassignContractorsResponse = ReassignContractorType[];

export enum OvertimeUndertimeStatus {
  not_resolved = 'not_resolved',
  paid_standard = 'paid_standard',
  paid_as_logged = 'paid_as_logged',
  approved_change = 'approved_change',
  reassigned = 'reassigned',
  undo = 'undo',
}
export type OvertimeUndertimeRequestStatusType = keyof typeof OvertimeUndertimeStatus;

export interface OvertimeUndertimeRequestType {
  id: string;
  requestID: string;
  associate: {
    first_name: string;
    last_name: string;
    department: string;
    userName: string;
    id: string;
  };
  project: {
    name: string;
    delivery: string;
  };
  planned_hours: number;
  actual_hours: number;
  recommended_sum: number;
  sum: number;
  initialStatus: OvertimeUndertimeRequestStatusType;
  status: OvertimeUndertimeRequestStatusType;
  approver: string;
  comment?: string;
  children: {
    project: {
      name: string;
      delivery: string;
      id: number;
    };
    planned_hours: number;
    actual_hours: number;
    recommended_sum: number;
  }[];
  type_of_association: 'Associate' | 'Freelancer';
}
