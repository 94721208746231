import { COMPANY } from 'api';

function getCompanyName() {
  const company = process.env.REACT_APP_COMPANY;

  if (!company) {
    console.warn('Company env is empty. Fallback to default value');
    return COMPANY.sherp;
  }

  if (!Object.values(COMPANY).includes(company as COMPANY)) {
    console.warn('Company has not been found in envs. Fallback to default value');
  }

  return company as COMPANY;
}

export default getCompanyName;
