import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { COMPANY, DaysOffInfo, ErpContractor } from 'api';
import { useFetchErp } from 'hooks';
import { getCompanyName } from 'utils';

function useErpProfileQuery(
  contractorId: string,
  options?: UseQueryOptions<ErpContractor>,
): UseQueryResult<ErpContractor> {
  const authorisedFetch = useFetchErp();

  return useQuery<ErpContractor>(
    ['erpProfile', contractorId],
    async () => {
      const response = await authorisedFetch(`/public/contractors/id/${contractorId}`, {
        method: 'GET',
      });

      const { daysOffInfo, ...rest }: ErpContractor = await response.json();

      return {
        ...rest,
        daysOffInfo: daysOffInfoStrategy(daysOffInfo),
      };
    },
    options,
  );
}

export default useErpProfileQuery;

const excludedRequestTypesPathStrategy = (company: COMPANY) => {
  switch (company) {
    case COMPANY.yalantis:
      return COMPANY.yalantis;
    case COMPANY.tallium:
      return COMPANY.tallium;

    default:
      return COMPANY.sherp;
  }
};

const excludedRequestTypesByCompany = {
  [COMPANY.yalantis]: ['undocumentedSickLeaves'],
  [COMPANY.tallium]: ['documentedSickLeaves'],
  [COMPANY.sherp]: [''],
};

const path = excludedRequestTypesPathStrategy(getCompanyName());

const daysOffInfoStrategy = (daysOffInfo: ErpContractor['daysOffInfo']) => {
  if (!daysOffInfo) return daysOffInfo;

  const excludedRequestTypes = excludedRequestTypesByCompany[path];

  const updated: { [key: string]: unknown } = {};

  for (const [key, value] of Object.entries(daysOffInfo)) {
    if (!excludedRequestTypes.includes(key)) {
      updated[key] = value;
    }
  }

  return updated as Partial<DaysOffInfo>;
};
