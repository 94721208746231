import { RequestType, TimeOffBalance, timeOfBalanceTitle, ErpContractor } from 'api';

export default function createTimeOffBalance(daysOffInfo: ErpContractor['daysOffInfo']) {
  if (!daysOffInfo) return [];

  const timeOffBalance: TimeOffBalance[] = [];

  if (daysOffInfo?.paidVacationLastYear) {
    timeOffBalance.push(
      createBalance({
        title: timeOfBalanceTitle.paid_vacation_last_year,
        type: RequestType.paidVacation,
        available: daysOffInfo.paidVacationLastYear.left,
        used: daysOffInfo.paidVacationLastYear.used,
      }),
    );
  }

  if (daysOffInfo?.paidVacation) {
    timeOffBalance.push(
      createBalance({
        title: timeOfBalanceTitle.paid_vacation,
        type: RequestType.paidVacation,
        available: daysOffInfo.paidVacation?.left,
        used: daysOffInfo.paidVacation?.used,
      }),
    );
  }

  if (daysOffInfo?.paidVacationNextYear) {
    timeOffBalance.push(
      createBalance({
        title: timeOfBalanceTitle.paid_vacation_next_year,
        type: RequestType.paidVacation,
        available: daysOffInfo.paidVacationNextYear.left,
        used: daysOffInfo.paidVacationNextYear.used,
      }),
    );
  }

  if (daysOffInfo?.undocumentedSickLeaves) {
    timeOffBalance.push(
      createBalance({
        title: timeOfBalanceTitle.undocumented_sick_leave,
        type: RequestType.undocumentedSickLeave,
        available: daysOffInfo?.undocumentedSickLeaves.left,
        used: daysOffInfo?.undocumentedSickLeaves.used,
      }),
    );
  }

  if (daysOffInfo?.documentedSickLeaves) {
    timeOffBalance.push(
      createBalance({
        title: timeOfBalanceTitle.documented_sick_leave,
        type: RequestType.documentedSickLeave,
        available: daysOffInfo.documentedSickLeaves.left,
        used: daysOffInfo.documentedSickLeaves.used,
      }),
    );
  }

  if (typeof daysOffInfo?.unpaidVacationUsed === 'number') {
    timeOffBalance.push(
      createBalance({
        title: timeOfBalanceTitle.unpaid_vacation,
        type: RequestType.unpaidVacation,
        available: 0,
        used: daysOffInfo.unpaidVacationUsed,
      }),
    );
  }

  if (typeof daysOffInfo?.additionalDaysOffUsed === 'number') {
    timeOffBalance.push(
      createBalance({
        title: timeOfBalanceTitle.additional_vacation,
        type: RequestType.additionalVacation,
        available: 0,
        used: daysOffInfo.additionalDaysOffUsed,
      }),
    );
  }

  return timeOffBalance;
}

function createBalance({
  title,
  type,
  available,
  used,
}: {
  title: string;
  type: RequestType;
  available: number;
  used: number;
}): TimeOffBalance {
  return { title, type, available, used };
}
